export const ali_projects=[
  {
    id:1,
    name:'弹性计算',
    instructions:'云服务器 ECS、GPU 云服务器、FPGA 云服务器、弹性裸金属服务器、轻量应用服务器...',
    projects:[
      {
        name:'云服务器 ECS',
        instructions:'从安全型到内存型、从进阶型到入门型的云服务器',
        price:'￥1992.00/6月起',
      },
      {
        name:'轻量应用服务器',
        instructions:'可快速搭建且易于管理的轻量级云服务器',
        price:'￥612.00/年起',
      },
      {
        name:'GPU 云服务器',
        instructions:'基于GPU应用的计算服务，实时高速，并行计算跟浮点计算能力强',
        price:'￥612.00/年起',
      },
      {
        name:'ECS云服务器内存型',
        instructions:'CPU与内存配比1：8 最新代企业级产品，性能全面提升',
        price:'￥3258.00/6月起',
      },
      {
        name:'高主频计算型服务器',
        instructions:'高主频计算型服务器',
        price:'￥3450.00/6月起',
      },
      {
        name:'高主频通用型服务器',
        instructions:'降低虚拟化开销，提供稳定可预期的超高性能',
        price:'￥3426.00/6月起',
      },
    ],
    projects_type:[
      {
        name: '云服务器',
        projects: [
          {
            name: '云服务器 ECS',
            instructions: '从安全型到内存型、从进阶型到入门型的云服务器'
          },
          {
            name: '弹性裸金属服务器（神龙）',
            instructions: '兼具虚拟机的弹性和物理机的高性能、安全物理隔离、分钟交付、云产品全兼容'
          },
          {
            name: '轻量应用服务器',
            instructions: '可快速搭建且易于管理的轻量级云服务器'
          },
          {
            name: 'GPU 云服务器',
            instructions: 'GPU实例、强大的计算性能、弹性按需扩展'
          },
          {
            name: 'FPGA 云服务器',
            instructions: 'FPGA实例、低时延可编程硬件加速服务'
          },
          {
            name: '专有宿主机',
            instructions: '安全合规，构建公共云上的专有资源池'
          },
          {
            name: 'ALIBABA CLOUD LINUX 2',
            instructions: 'ALIBABA CLOUD LINUX 2(原ALIYUN LINUX 2) - 阿里云原生免费操作系统，为您提供稳定、可靠、高性能的操作系统环境'
          },
        ]
      },
      {
        name: '高性能计算 HPC',
        projects: [
          {
            name: '超级计算集群',
            instructions: '支持RDMA提供极致并行计算性能实例规格'
          },
          {
            name: '弹性高性能计算 E-HPC',
            instructions: '加速深度学习、渲染和科学计算的 GPU 物理机'
          },
          {
            name: '批量计算',
            instructions: '大规模并行批处理计算服务'
          },
        ]
      },
      {
        name: '容器服务',
        projects: [
          {
            name: '容器服务',
            instructions: '支持微服务架构、全生命周期管理的DOCKER服务'
          },
          {
            name: '容器服务 KUBERNETES 版',
            instructions: '提供高性能可伸缩的容器应用管理能力，支持企业级 KUBERNETES 容器化应用的全生命周期管理'
          },
          {
            name: '弹性容器实例 ECI',
            instructions: '提供敏捷安全的 SERVERLESS 容器运行服务'
          },
          {
            name: '容器镜像服务',
            instructions: '提供容器镜像、HELM CHART 云原生资产的安全托管和高效分发'
          },
        ]
      },
      {
        name: '弹性编排',
        projects: [
          {
            name: '弹性伸缩',
            instructions: '自动调整弹性计算资源的管理服务'
          },
          {
            name: '资源编排',
            instructions: '复杂环境部署利器，提供资源批量复制、创建和配置'
          },
        ]
      },
      {
        name: '应用托管',
        projects: [
          {
            name: 'WEB应用托管服务（WEB+）',
            instructions: 'WEB应用托管服务（WEB+）是一款用来运行并管理WEB类、移动类和API类应用程序的PAAS产品。您可以使用JAVA、PYTHON、NODE.JS、PHP、RUBY和GO等多种语言编写并构建应用程序。在无需管理底层基础设施的情况下，便可简单、高效、安全而又灵活的对应用进行部署、伸缩、调整和监控。'
          },
        ]
      },
      {
        name: 'SERVERLESS',
        projects: [
          {
            name: '函数计算',
            instructions: '一个事件驱动的全托管计算服务，通过函数计算，无需管理服务器等基础设施，上传编写代码'
          },
          {
            name: 'SERVERLESS 应用引擎',
            instructions: 'SERVERLESS应用引擎（SERVERLESS APP ENGINE，简称 SAE）是面向应用的 SERVERLESS PAAS平台，帮助 PAAS 层用户免运维 IAAS，按需使用，按量计费，实现低门槛微服务应用上云，有效解决成本及效率问题。支持 SPRING CLOUD、DUBBO和HSF 等流行的开发框架，真正实现了 SERVERLESS 架构和微服务架构的完美融合'
          },
          {
            name: '函数工作流（公测中）',
            instructions: '用来协调多个分布式任务执行的全托管云服务。您可以用顺序、分支、并行等方式来编排任务，FNF会按照设定好的步骤执行任务，并自动跟踪任务的状态转换，在必要时执行用户定义的重试逻辑，确保工作流执行完成。'
          },
        ]
      },
      {
        name: '其他',
        projects: [
          {
            name: '云桌面',
            instructions: '提供了远程桌面功能、非线编软件等完整图形图像处理流程，能实现高清桌面实时交互'
          },
        ]
      },
    ]
  },
  {
    id:2,
    name:'数据库',
    instructions:'云数据库 POLARDB、RDS SQL SERVER、RDS MYSQL、RDS POSTGRESQL、OCEANBASE...',
    projects:[
      {
        name:'云数据库 POLARDB',
        instructions:'阿里巴巴自主研发的下一代关系型分布式云原生数据库',
        price:'￥2937.60/年起',
      },
      {
        name:'RDS SQL SERVER',
        instructions:'SQL SERVER企业版授权许可，主实例故障后自动切换至镜像实例',
        price:'￥4680.00/年起',
      },
      {
        name:'RDS MYSQL',
        instructions:'全球最受欢迎的开源数据库之一 高安全等级，高稳定，5倍性能提升',
        price:'￥3204.00/年起',
      },
      {
        name:'RDS POSTGRESQL',
        instructions:'被业界誉为“最先进的开源数据库”，面向复杂SQL的OLTP业务场景',
        price:'￥3204.00/年起',
      },
      {
        name:'OCEANBASE',
        instructions:'在普通硬件实现金融级高可用，创造了6100万次/秒处理峰值的业内纪录',
        price:'￥16692.00/年起',
      },
      {
        name:'云数据库专属集群 MYBASE',
        instructions:'具有云资源独享、支持资源超分配，开放部分数据库和OS权限等特点',
        price:'￥48282.84/年起',
      },
    ],
    projects_type:[
      {
        name: '云数据库专属集群',
        projects: [
          {
            name: '云数据库专属集群MYBASE',
            instructions: 'MYBASE是阿里云专为企业级用户定制优化的解决方案，支持支持MYSQL，POSTGRESQL，SQL SERVER和REDIS等多种数据库，自主可控的专属集群服务'
          }
        ]
      },
      {
        name: '关系型数据库',
        projects: [
          {
            name: '云数据库 POLARDB',
            instructions: '自研全新一代云数据库，与 MYSQL、POSTGRESQL 100%兼容，高度兼容ORACLE语法。性能最高提升至传统MYSQL的6倍，存储容量可达 100T'
          },
          {
            name: '云数据库 RDS MYSQL 版',
            instructions: 'MYSQL 是全球最受欢迎的开源数据库之一，作为开源软件组合 LAMP（LINUX + APACHE + MYSQL + PERL/PHP/PYTHON） 中的重要一环，广泛应用于各类应用场景'
          },
          {
            name: '云数据库 RDS MARIADB TX 版',
            instructions: '基于MARIADB企业版全球独家合作认证，提供ORACLE兼容性及众多企业级数据库特性，支持包括MYSQL INNODB等多种存储引擎，为不同需求的用户提供灵活的选择。'
          },
          {
            name: '云数据库 RDS SQL SERVER 版',
            instructions: 'SQL SERVER是发行最早的商用数据库产品之一，支持复杂的SQL查询，性能优秀，对基于WINDOWS平台.NET架构的应用程序具有完美的支持'
          },
          {
            name: '云数据库 RDS POSTGRESQL 版',
            instructions: 'POSTGRESQL被业界誉为“最先进的开源数据库”，面向企业复杂SQL的OLTP业务场景，支持NOSQL数据类型'
          },
          {
            name: '云数据库 RDS PPAS 版',
            instructions: '基于POSTGRESQL高度兼容ORACLE语法的数据库服务，为用户提供易于操作的迁移工具'
          },
          {
            name: '云数据库 OCEANBASE',
            instructions: 'OCEANBASE 是阿里巴巴和蚂蚁金服 100% 自主研发的金融级分布式关系数据库，在普通硬件上实现金融级高可用，具备在线水平扩展能力，创造了 6100 万次/秒处理峰值的业内纪录'
          },
          {
            name: '分布式关系型数据库服务 DRDS',
            instructions: 'DRDS 是阿里巴巴集团自主研发的分布式数据库中间件产品，专注于解决单机关系型数据库扩展性问题，具备轻量(无状态)、灵活、稳定、高效等特性'
          },
        ]
      },
      {
        name: 'NOSQL数据库',
        projects: [
          {
            name: '云数据库 REDIS 版',
            instructions: '高可靠双机热备架构及可无缝扩展的集群架构，满足高读写性能场景及容量需弹性变配的业务需求'
          },
          {
            name: '云数据库 MONGODB 版',
            instructions: '云数据库MONGODB版支持REPLICASET和SHARDING两种部署架构，具备安全审计，时间点备份等多项企业能力。在互联网、物联网、游戏、金融等领域被广泛采用'
          },
          {
            name: '云原生多模数据库LINDORM',
            instructions: '适用于任何规模、多种模型的云原生数据库服务，支持海量数据的低成本存储处理和弹性按需付费，兼容多种开源标准接口，是互联网、IOT、车联网、广告、社交、监控、游戏、风控等场景首选数据库，也是为阿里巴巴核心业务提供关键支撑的数据库之一'
          },
          {
            name: '云数据库 HBASE 版',
            instructions: '面向大数据领域的一站式NOSQL服务，100%兼容开源HBASE并深度扩展，支持海量数据下的实时存储、高并发吞吐、轻SQL分析、全文检索、时序时空查询等能力'
          },
          {
            name: '图数据库 GDB',
            instructions: '是一种支持属性图模型，用于处理高度连接数据查询与存储的实时可靠的在线数据库，支持 TINKERPOP GREMLIN 查询语言，可以帮助用户快速构建基于高度连接的数据集的应用程序'
          },
          {
            name: 'TSDB 时序时空数据库',
            instructions: '物联网亿级设备数据的高并发写入，低成本存储，数据计算分析，数据可视化功能的专业数据库服务'
          },
          {
            name: '表格存储 TABLESTORE',
            instructions: '高并发、低延时、无限容量的NOSQL数据存储服务'
          },
          {
            name: '云数据库CASSANDRA版',
            instructions: '一款高可靠的NOSQL分布式数据库服务，ALWAYS ONLINE，支持跨数据中心多活，支持单数据中心到多数据中心在线扩展；支持类SQL语法CQL；一致性可调（支持配置强一致性，最终一致性等多种方式）；支持TTL'
          },
        ]
      },
      {
        name: '数据仓库',
        projects: [
          {
            name: '分析型数据库 MYSQL版',
            instructions: '千亿级记录、毫秒级查询的OLAP系统'
          },
          {
            name: '分析型数据库 POSTGRESQL版',
            instructions: '在线MPP大规模并行处理数据仓库服务'
          },
          {
            name: 'HYBRIDDB FOR MYSQL',
            instructions: '支持海量数据 OLTP 和 OLAP 的关系型数据库'
          },
          {
            name: '云原生数据湖分析 DLA',
            instructions: '全SERVERLESS架构，为海量数据提供普惠的分析能力'
          },
        ]
      },
      {
        name: '数据库生态工具',
        projects: [
          {
            name: '数据传输服务DTS',
            instructions: '支持关系型数据库、NOSQL、大数据(OLAP)等数据源间的数据迁移、同步、订阅'
          },
          {
            name: '数据库备份DBS',
            instructions: '为数据库提供连续数据保护，易用、低成本的备份服务'
          },
          {
            name: '数据管理 DMS',
            instructions: '比 PHPMYADMIN 更强大，比 NAVICAT 更易用'
          },
          {
            name: '数据库自治服务 DAS（原HDM）',
            instructions: '基于机器学习和专家经验实现数据库自感知、自修复、自优化、自运维及自安全，帮助用户消除数据库管理的复杂性'
          },
          {
            name: '数据库和应用迁移 ADAM',
            instructions: '为ORACLE数据库和应用迁移上云提供评估、改造、实施'
          },
        ]
      },
      {
        name: '数据库专家服务',
        projects: [
          {
            name: '数据库专家服务（公测中）',
            instructions: '以工具与数据库专业技能结合的方式，给客户提供云数据库产品本身能力范围之外的专业的数据库专家服务，主要包括：紧急救援，健康诊断、性能调优、护航保障、ORACLE迁移等服务'
          },
        ]
      },
    ]
  },
  {
    id:3,
    name:'存储服务与CDN',
    instructions:'对象存储 OSS、块存储、文件存储、CDN、安全加速 SCDN、全站加速 DCDN、PCDN...',
    projects:[
      {
        name:'对象存储 OSS',
        instructions:'适合图片/音视频等多媒体数据存储，数据实时处理，海量存储无上限',
        price:'￥99.00/年起',
      },
      {
        name:'存储容量单位包',
        instructions:'抵扣多种不同类型云盘的按量付费账单，兼具性价比与资源使用的灵活性',
        price:'￥204.00/年起',
      },
      {
        name:'文件存储',
        instructions:'适合多台ECS之间共享的文件存储服务，挂载即享，无需开发',
        price:'￥279.00/年起',
      },
      {
        name:'CDN',
        instructions:'将源站内容分发至最接近用户的节点，提高用户访问的响应速度和成功率',
        price:'￥35.20/年起',
      },
      {
        name:'安全加速 SCDN',
        instructions:'为网站做加速的同时，提供独享资源保证客户间相互隔离，业务更加安全',
        price:'￥9600.00/月起',
      },
      {
        name:'CDN/全站加速流量包',
        instructions:'适用于CDN和DCDN下行流量抵扣',
        price:'￥83.60/6月起',
      },
    ],
    projects_type:[
      {
        name: '云存储',
        projects: [
          {
            name: '对象存储 OSS',
            instructions: '比传统存储成本下降25%~75%的强安全企业级存储'
          },
          {
            name: '块存储',
            instructions: '可弹性扩展、高性能、高可靠的块级随机存储'
          },
          {
            name: '文件存储 NAS',
            instructions: '支持NFS、CIFS协议的可共享文件存储'
          },
          {
            name: '文件存储 CPFS',
            instructions: '云中完全托管的服务，可以轻松创建和运行高性能文件系统'
          },
          {
            name: '文件存储 HDFS（公测中）',
            instructions: '具备无限容量及性能扩展、单一命名空间、高可靠和高可用等特性的分布式文件系统'
          },
          {
            name: '归档存储',
            instructions: '适用于历史数据归档，强安全、高持久低成本存储'
          },
        ]
      },
      {
        name: '智能存储',
        projects: [
          {
            name: '智能云相册（公测中）',
            instructions: '提供影像文件存储、管理等基础功能以外，还支持对影像内容进行分类打标、面孔识别等智能分析'
          },
          {
            name: '智能媒体管理',
            instructions: '提供场景化构建的一站式数据应用解决方案，让AI等智能分析功能更加的普惠'
          },
        ]
      },
      {
        name: '混合云存储',
        projects: [
          {
            name: '云存储网关',
            instructions: '一款可在线下和云上部署的软网关'
          },
          {
            name: '混合云存储阵列',
            instructions: '集成了阿里云云存储网关的企业级统一存储阵列'
          },
        ]
      },
      {
        name: 'CDN与边缘',
        projects: [
          {
            name: 'CDN',
            instructions: '常用于视频、图片、文件加速的跨域网络分发'
          },
          {
            name: '安全加速 SCDN',
            instructions: '为网站做加速的同时，防护DDOS，CC，WEB应用攻击等危害'
          },
          {
            name: '全站加速 DCDN',
            instructions: '适用于动静混合型、纯动态型站点或应用的内容分发加速服务'
          },
          {
            name: 'PCDN',
            instructions: '适用于视频点播、直播、大文件下载等业务场景'
          },
          {
            name: '边缘节点服务 ENS',
            instructions: '提供基于CDN的边缘弹性基础设施'
          },
        ]
      },
    ]
  },
  {
    id:4,
    name:'云通信与网络',
    instructions:'短信服务、语音服务、流量服务、专有网络VPC、负载均衡SLB、NAT网关...',
    projects:[
      {
        name:'国内短信套餐包',
        instructions:'支持国内验证码、短信通知和推广短信，秒级触达，免运维',
        price:'￥0.036/条起',
      },
      {
        name:'国内语音通知套餐包',
        instructions:'无需购买号码，动态扩容增加并发',
        price:'￥0.10/分钟起',
      },
      {
        name:'共享流量包',
        instructions:'最便宜的公网流量产品，开通后可抵扣ECS等多产品的多地域的公网流量',
        price:'￥159.00/6月起',
      },
      {
        name:'负载均衡SLB',
        instructions:'GARTNER增速最快的负载均衡服务，轻松应对云上海量访问',
        price:'￥388.80/年起',
      },
      {
        name:'VPN网关',
        instructions:'IPSEC/SSL加密，线下分支机构轻松上云',
        price:'￥3825.00/年起',
      },
      {
        name:'NAT网关',
        instructions:'管理公网IP利器，支持SNAT/DNAT；让应用更安全，运维更简单',
        price:'￥3121.20/年起',
      },
    ],
    projects_type:[
      {
        name: '云通信',
        projects: [
          {
            name: '短信服务',
            instructions: '验证码和短信通知服务，三网合一快速到达'
          },
          {
            name: '语音服务',
            instructions: '语音通知和语音验证，支持多方通话'
          },
          {
            name: '流量服务',
            instructions: '轻松玩转手机流量，物联卡专供物联终端使用'
          },
          {
            name: '物联网无线连接服务',
            instructions: '支持所有联网卡形态，贴近行业的灵活资费套餐'
          },
          {
            name: '号码隐私保护',
            instructions: '提供私密专线能力嵌入到合作伙伴的业务流程'
          },
          {
            name: '号码认证服务（公测中）',
            instructions: '支持手机号一键认证，认证体验全新升级'
          },
          {
            name: '云通信网络加速（公测中）',
            instructions: '协同运营商能力，按需提速，提升上网体验'
          },
        ]
      },
      {
        name: '云上网络',
        projects: [
          {
            name: '专有网络 VPC',
            instructions: '构建逻辑隔离网络，确保资源安全'
          },
          {
            name: '云解析 PRIVATEZONE',
            instructions: '基于阿里云专有网络VPC环境的私有域名解析和管理服务'
          },
          {
            name: '负载均衡 SLB',
            instructions: '对多台云服务器进行流量分发的负载均衡服务'
          },
          {
            name: 'NAT 网关',
            instructions: '支持NAT转发、共享带宽的VPC网关'
          },
          {
            name: '弹性公网 IP',
            instructions: '独立的公网IP资源'
          },
          {
            name: '全局流量管理',
            instructions: '多IP应用服务的全球访问加速、智能调度、容灾切换'
          },
          {
            name: 'IPV6 转换服务（公测中）',
            instructions: '原有IPV4业务可快速面向IPV6网络侧用户提供服务'
          },
          {
            name: '共享带宽',
            instructions: '提供地域级带宽共享和复用功能，支持同地域下所有弹性公网IP共享带宽'
          },
          {
            name: '共享流量包',
            instructions: '按流量计费，开通后自动覆盖多个地域的产品并自动抵扣'
          },
        ]
      },
      {
        name: '跨地域网络',
        projects: [
          {
            name: '云企业网',
            instructions: '帮助用户打造具有企业级规模和通信能力的云上网络'
          },
        ]
      },
      {
        name: '混合云网络',
        projects: [
          {
            name: 'VPN 网关',
            instructions: '安全加密的云上云下数据互通方案'
          },
          {
            name: '智能接入网关（邀测中）',
            instructions: '通过智能接入网关实现INTERNET就近加密接入'
          },
          {
            name: '高速通道',
            instructions: '高速稳定的 VPC 互联和专线接入服务'
          },
        ]
      },
    ]
  },
  {
    id:5,
    name:'云安全',
    instructions:'WEB应用防火墙、DDOS高防IP、SSL 证书、态势感知、安骑士、堡垒机、等保服务...',
    projects:[
      {
        name:'WEB应用防火墙',
        instructions:'防注入，防CC ，一站式WEB安全解决方案',
        price:'￥3880.00/月起',
      },
      {
        name:'DDOS高防IP',
        instructions:'T级防御带宽，海量清洗，智能防御DDOS攻击，无需人工干预',
        price:'￥20800.00/月起',
      },
      {
        name:'云服务器 ECS',
        instructions:'网站必备基础安全产品，解决网站在浏览器显示“不安全”的提示',
        price:'￥3000.00/年起',
      },
      {
        name:'云安全中心',
        instructions:'有效保护主机安全，让安全运维变得简单，防勒索防篡改',
        price:'￥1080.00/年起',
      },
      {
        name:'堡垒机',
        instructions:'构建云上统一、安全、高效运维通道',
        price:'￥1520.00/月起',
      },
      {
        name:'等保服务',
        instructions:'全面覆盖等保定级、备案、建设整改以及测评阶段，高效通过等保测评',
        price:'￥200000.00/起',
      },
    ],
    projects_type:[
      {
        name: '云安全',
        projects: [
          {
            name: 'DDOS高防IP',
            instructions: '300G按天付费，防御超大流量DDOS攻击'
          },
          {
            name: 'WEB应用防火墙',
            instructions: '防注入，防CC ，一站式WEB安全解决方案'
          },
          {
            name: 'SSL 证书',
            instructions: '实现网站HTTPS化使网站防劫持、防篡改、防监听'
          },
          {
            name: '云安全中心（态势感知）',
            instructions: '安全统一告警平台，原始日志检索和安全事件调查'
          },
          {
            name: '云防火墙',
            instructions: '基于业务可视的结果、实现业务梳理和业务隔离'
          },
          {
            name: '堡垒机',
            instructions: '运维操作记录，满足合规审计要求'
          },
          {
            name: '漏洞扫描',
            instructions: '漏洞、挂马、篡改、垃圾广告、敏感内容扫描'
          },
          {
            name: '操作审计（公测中）',
            instructions: '详细记录控制台和 API 操作'
          },
          {
            name: '云安全中心（安骑士）',
            instructions: '全方位服务器安全检测、响应和防护'
          },
        ]
      },
      {
        name: '身份管理',
        projects: [
          {
            name: '访问控制',
            instructions: '管理多因素认证、子账号与授权、角色与 STS 令牌'
          },
          {
            name: '应用身份服务（公测中）',
            instructions: '防注入，防CC 帮助企业客户管理所有应用的统一认证和授权，不论应用是部署在本地、云上还是使用三方SAAS服务。'
          },
        ]
      },
      {
        name: '数据安全',
        projects: [
          {
            name: '数据库审计',
            instructions: '符合数据库合规审计要求'
          },
          {
            name: '加密服务',
            instructions: '加密，符合监管合规的硬件加密服务'
          },
          {
            name: '敏感数据保护',
            instructions: '敏感数据保护产品是支持用户云上数据使用安全的产品。'
          },
          {
            name: '密钥管理服务',
            instructions: '安全、易用、低成本的密钥管理服务'
          },
        ]
      },
      {
        name: '业务安全',
        projects: [
          {
            name: '游戏盾',
            instructions: '解决游戏行业特有的TCP协议的CC攻击问题'
          },
          {
            name: '内容安全',
            instructions: '文本、图像、视频直播的内容安全检测服务'
          },
          {
            name: '风险识别',
            instructions: '解决企业账户、营销、交易等环节的欺诈问题'
          },
          {
            name: '实人认证',
            instructions: '实人可信模型进行自然人真实身份核验服务'
          },
          {
            name: '爬虫风险管理',
            instructions: '专业检测高级爬虫，降低爬虫、自动化工具对网站的业务影响'
          },
        ]
      },
      {
        name: '安全服务',
        projects: [
          {
            name: '安全管家',
            instructions: '专业、高效的入侵响应、安全护航、安全托管服务'
          },
          {
            name: '渗透测试',
            instructions: '以攻击者思维，模拟黑客对业务进行全面安全测试，帮助企业挖掘安全缺陷和漏洞。'
          },
          {
            name: '安全众测',
            instructions: '企业发出安全测试需求，白帽黑客依据「测试需求」给出测试报告，企业依据报告的有效性给予白帽黑客一定现金奖励'
          },
          {
            name: '等保咨询',
            instructions: '帮助完成安全整改工作，指导客户通过等保测评。'
          },
          {
            name: '应急响应',
            instructions: '提供的黑客入侵事件处理服务，帮助客户快速恢复业务'
          },
          {
            name: '安全培训',
            instructions: '定制个性化的安全培训课程计划'
          },
          {
            name: '安全评估',
            instructions: '分析出业务中存在的安全问题，指导加固和修复'
          },
          {
            name: '代码审计',
            instructions: '检查源代码中的错误及安全漏洞，提供修订措施'
          },
          {
            name: '安全加固',
            instructions: '对外网或内网主机进行全方位的基线加固和组件升级'
          },
          {
            name: '安全通告',
            instructions: '实时监测、周期性度量风险隐患，您可以根据通告信息，轻松掌握自有IT资产的安全漏洞状态，及时跟踪修补IT资产漏洞，提高企业脆弱性管理能力'
          },
          {
            name: 'PCI DSS合规咨询',
            instructions: '对于所有涉及支付卡行业的安全方面作出标准的要求'
          },
        ]
      },
    ]
  },
  {
    id:6,
    name:'大数据',
    instructions:'大数据计算MAXCOMPUTE、数据可视化DATAV、数据开发DATAWORKS...',
    projects:[
      {
        name:'MAXCOMPUTE',
        instructions:'面向分析的大数据计算服务，经济并高效的分析处理海量数据',
        price:'￥1500.00/月起',
      },
      {
        name:'DATAV数据可视化',
        instructions:'通过图形化的界面轻松搭建专业水准的可视化应用',
        price:'￥475.00/月起',
      },
      {
        name:'DATAWORKS',
        instructions:'专业高效、安全可靠的一站式大数据开发与治理平台',
        price:'￥20000.00/起',
      },
      {
        name:'DATAPHIN',
        instructions:'从数据接入到数据消费全链路的智能数据构建与管理的大数据能力',
        price:'￥75500.00/月起',
      },
      {
        name:'QUICK BI',
        instructions:'无缝对接各类云上数据库和自建数据库，0代码鼠标拖拽式操作',
        price:'￥15300.00/年起',
      },
      {
        name:'交互式分析HOLOGRES',
        instructions:'为大数据设计的实时交互式分析产品，与MAXCOMPUTE无缝打通',
        price:'￥6440.00/月起',
      },
    ],
    projects_type:[
      {
        name: '大数据计算',
        projects: [
          {
            name: 'MAXCOMPUTE',
            instructions: '建设数据仓库，钱省一半'
          },
          {
            name: 'E-MAPREDUCE',
            instructions: '基于 HADOOP/SPARK 的大数据处理分析服务'
          },
          {
            name: '实时计算',
            instructions: '流计算引擎，让数据报表刷新更快'
          },
          {
            name: 'HOLOGRES交互式分析',
            instructions: '一份数据同时用于实时分析与在线服务'
          },
        ]
      },
      {
        name: '数据可视化',
        projects: [
          {
            name: 'DATAV数据可视化',
            instructions: '做出领导惊喜的可视化大屏'
          },
        ]
      },
      {
        name: '大数据搜索与分析',
        projects: [
          {
            name: '开放搜索',
            instructions: '结构化数据搜索托管服务'
          },
          {
            name: '日志服务',
            instructions: '一站式解决日志收集、存储、查询和分析'
          },
          {
            name: 'ELASTICSEARCH',
            instructions: '全新的云上ELK服务，提供商业版X-PACK插件'
          },
          {
            name: '关系网络分析',
            instructions: '构建万物互联，追寻业务线索'
          },
          {
            name: 'QUICK BI',
            instructions: '一款专为云上用户和企业量身打造的新一代自助式智能BI服务平台，其简单易用的可视化操作和灵活高效的多维分析能力，让精细化数据洞察为商业决策保驾护航'
          },
        ]
      },
      {
        name: '数据开发',
        projects: [
          {
            name: 'DATAWORKS',
            instructions: '一站式大数据开发管理平台'
          },
          {
            name: 'DATAPHIN（公测中）',
            instructions: '一站式智能数据构建与管理平台'
          },
          {
            name: '数据集成',
            instructions: '消除数据孤岛，快速集中数据'
          },
        ]
      },
      {
        name: '大数据应用',
        projects: [
          {
            name: '企业图谱',
            instructions: '透视企业关系，洞察企业风险'
          },
          {
            name: '智能推荐（公测中）',
            instructions: '基于阿里巴巴领先的大数据和人工智能技术，结合在电商、内容、新闻、视频直播和社交等多个行业领域的积累，为全球企业及开发者提供个性化推荐服务'
          },
        ]
      },
    ]
  },
]

export const pc_origin = process.env.NODE_ENV === 'production' ? "http://www.ssgctech.com" :"http://172.18.0.186:8081";

export const onLine ="http://p.qiao.baidu.com/cps/chat?siteId=16366120&userId=32389428&siteToken=b6118989f44539e18483bf35a417b39d"