<template>
  <header class="header">
    <div class="head-box">
      <router-link to="/">
        <img class="logo" src="../assets/header/logo.png" />
      </router-link>
      <div class="toolbar" @click="open = !open" :class="{ 'open': open }">导航</div>
    </div>
    <div class="menu-container" :class="{ 'open-menu': open }">
      <div class="menu-item">
        <div class="item-title" :class="{ 'on': $route?.name?.split('-')[0] == 'index' }">
          <em class="icon_1"></em>
          <router-link to="/">首页</router-link>
        </div>
      </div>
      <div class="menu-item">
        <div class="item-title" :class="{ 'on': $route?.name?.split('-')[0] == 'cloud' }">
          <em class="icon_2"></em>
          产品服务
        </div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'alicloud' }"><router-link
            to="/cloud/alicloud">阿里云产品</router-link></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'dingtalk_professional' }"><router-link
            to="/cloud/dingtalk/professional">钉钉专业版</router-link></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'dingtalk_exclusive' }"><router-link
            to="/cloud/dingtalk/exclusive">钉钉专属版</router-link></div>
        <div class="item-menu"><a href="javascript:void(0)" @click="handleLink('ddAI')">钉钉AI助理</a></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'teambition' }"><router-link
            to="/cloud/teambition">Teambition</router-link></div>
        <div class="item-menu"><a href="javascript:void(0)" @click="handleLink('ddYIDA')">钉钉低代码</a></div>
        <div class="item-menu"><a href="javascript:void(0)" @click="handleLink('ddAuthine')">奥哲低代码</a></div>
      </div>
      <div class="menu-item">
        <div class="item-title" :class="{ 'on': $route?.name?.split('-')[0] == 'solution' }">
          <em class="icon_3"></em>
          解决方案
        </div>
        <div class="item-menu"><a href="javascript:void(0)" @click="handleLink('sAI')">通义千问AI大模型</a></div>
        <div class="item-menu"><a href="javascript:void(0)" @click="handleLink('sAll')">全场景沟通协作</a></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'synergy' }"><router-link
            to="/solution/synergy">数智协同·云钉</router-link></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'planning' }"><router-link
            to="/solution/planning">数智决策·云图</router-link></div>
        <div class="item-menu" :class="{ 'on': $route?.name?.split('-')[1] == 'member' }"><router-link
            to="/solution/member">数智人资·云效</router-link></div>
      </div>
      <div class="menu-item">
        <div class="item-title" :class="{ 'on': $route?.name?.split('-')[0] == 'about' }">
          <em class="icon_4"></em>
          <router-link to="/about">关于我们</router-link>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import { pc_origin } from "@/data.ts";
export default {
  data() {
    return {
      open: false
    }
  },
  methods: {
    handleLink(page) {
      // const hostname = "http://www.ssgctech.com";
      const hostname = pc_origin;
      const pageMap = {
        ddAI: "/product-dd-ai.html",// 钉钉AI助理
        ddYIDA: "/product-dd-yida.html",// 钉钉低代码
        ddAuthine: "/authine.html",// 钉钉低代码
        sAI: "/solution-ai.html",// AI大模型解决方案
        sAll: "/solution-all.html"// 全场景沟通协作解决方案
      }
      window.open(hostname + pageMap[page]);
    }
  },
  watch: {
    '$route': function () {
      this.open = false;
    }
  }
}
</script>
<style lang="less" scoped>
.header {
  position: fixed;
  width: 100%;
  height: 48px;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 0 13px;
  z-index: 4;

  .head-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  img.logo {
    width: 102.5px;
    height: 32px;
    display: block;
  }

  .toolbar {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #383838;
    padding-right: 25px;
    position: relative;
    font-family: Source Han Sans CN;
    font-weight: 300;

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 18px;
      height: 3px;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      background-color: #F23D49;
      transition: .2s transform;
    }

    &::before {
      transform: translateY(calc(-50% - 4px));
    }

    &::after {
      transform: translateY(calc(-50% + 4px));
    }

    &.open {
      &::before {
        transform: translateY(-50%) rotate(45deg);
      }

      &::after {
        transform: translateY(-50%) rotate(-45deg);
      }
    }
  }

  .menu-container {
    position: absolute;
    z-index: 3;
    left: 0;
    width: 100vw;
    top: 48px;
    background-color: rgba(0, 0, 0, .9);
    height: 0;
    transition: .2s height;
    box-sizing: border-box;
    padding: 0 25px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;

    &.open-menu {
      height: calc(100vh - 48px);
    }

    .menu-item {
      border-bottom: 1px solid #454545;
      display: flex;
      flex-wrap: wrap;

      &:first-child {
        padding-top: 20px;
      }

      &:last-child {
        border-bottom: 0 none;
        padding-bottom: 20px;
      }

      .item-title {
        display: flex;
        align-items: center;
        font-size: 17px;
        color: #fff;
        font-weight: bold;
        line-height: 1.5;
        padding: 10px 0;
        width: 100%;
        min-width: 100%;

        >em {
          display: block;
          width: 27px;
          height: 27px;
          margin-right: 16px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;

          &.icon_1 {
            background-image: url(../assets/header/home.png);
          }

          &.icon_2 {
            background-image: url(../assets/header/cloud.png);
          }

          &.icon_3 {
            background-image: url(../assets/header/solution.png);
          }

          &.icon_4 {
            background-image: url(../assets/header/about.png);
          }
        }

        &.on {
          color: #F23D49;

          >em {
            &.icon_1 {
              background-image: url(../assets/header/home_on.png);
            }

            &.icon_2 {
              background-image: url(../assets/header/cloud_on.png);
            }

            &.icon_3 {
              background-image: url(../assets/header/solution_on.png);
            }

            &.icon_4 {
              background-image: url(../assets/header/about_on.png);
            }
          }
        }
      }

      .item-menu {
        padding: 10px 0;
        padding-left: 43px;
        font-size: 15px;
        color: #fff;
        font-weight: 300;
        line-height: 1.5;
        width: 50%;
        min-width: 50%;

        &.on {
          color: #F23D49;
        }

        &:last-child {
          padding-bottom: 20px;
        }
      }
    }
  }
}
</style>
